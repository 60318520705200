import { useCallback, useState, useRef, useEffect } from "react";
import Image from "next/image";
import PriceSection from "@/components/buy/filter/PriceSection";
import YearSection from "@/components/buy/filter/YearSection";
import MilesSection from "@/components/buy/filter/MilesSection";
import CarTypeSection from "@/components/buy/filter/CarTypeSection";
import MoreFilterSection from "@/components/buy/filter/MoreFilterSection";
import CloseIcon from "@/components/Icon/CloseIcon";
import { MoreFilter } from "@/interfaces/MoreFilter";
import { initFilters } from "@/libs/constants";
import { useSearchValue } from "@/contexts/contextSearch";
import useOutsideClick from "@/hooks/useOutsideClick";
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_YEAR,
  MAX_YEAR,
  MIN_MILES,
  MAX_MILES,
} from "@/libs/constants";
import { isMobileDevice } from "@/libs/utils";
import { verificationFilters } from "@/interfaces/User";

type Props = {
  moreFilterData: any;
  isFilterModal: Boolean;
  setIsFilterModal: Function;
};

const FilterModal = ({
  moreFilterData,
  isFilterModal,
  setIsFilterModal,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    minMiles,
    setMinMiles,
    maxMiles,
    setMaxMiles,
    isVerified,
    setIsVerified,
    bodyType,
    setBodyType,
    moreFiltersArr,
    setMoreFiltersArr,
    setCurrent,
    clearFilters,
    triggerReset,
  } = useSearchValue();
  const [minPriceInModal, setMinPriceInModal] = useState<number>(MIN_PRICE);
  const [maxPriceInModal, setMaxPriceInModal] = useState<number>(MAX_PRICE);
  const [minYearInModal, setMinYearInModal] = useState<number>(MIN_YEAR);
  const [maxYearInModal, setMaxYearInModal] = useState<number>(MAX_YEAR);
  const [minMilesInModal, setMinMilesInModal] = useState<number>(MIN_MILES);
  const [maxMilesInModal, setMaxMilesInModal] = useState<number>(MAX_MILES);
  const [isVerifiedInModal, setIsVerifiedInModal] =
    useState<string>(isVerified);
  const [bodyTypeInModal, setBodyTypeInModal] = useState<Array<string>>([]);
  const [moreFiltersArrInModal, setMoreFiltersArrInModal] =
    useState<MoreFilter>(initFilters);

  const changeVerified = useCallback(() => {
    setIsVerifiedInModal(
      isVerifiedInModal ? "" : verificationFilters.fullyVerified
    );
  }, [isVerifiedInModal]);

  const save = () => {
    setIsFilterModal(false);
    document.body.style.overflowY = "scroll";
    setCurrent(0);
    setMinPrice(minPriceInModal);
    setMaxPrice(maxPriceInModal);
    setMinYear(minYearInModal);
    setMaxYear(maxYearInModal);
    setMinMiles(minMilesInModal);
    setMaxMiles(maxMilesInModal);
    setIsVerified(isVerifiedInModal);
    setBodyType(bodyTypeInModal);
    setMoreFiltersArr(moreFiltersArrInModal);
  };

  const clear = () => {
    clearFilters();
  };

  useEffect(() => {
    console.log("here", triggerReset);
    if (!triggerReset) {
      return;
    }
    console.log("Filters cleared");
    setIsVerifiedInModal("");
  }, [triggerReset]);
  useOutsideClick(modalRef, isFilterModal, setIsFilterModal);
  const isSmallPhone = window.innerWidth < 385;

  return (
    <div className="fixed top-0 left-0 w-full h-screen justify-center items-center overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
      <div
        className={`w-full xs:w-[390px] mx-auto opacity-100 bg-white text-[#333] border-1 border-2-gray-400 shadow-md rounded-xl py-4 mt-10 ${
          isMobileDevice() && "mb-[20px] mt-[40px]  h-[calc(90vh-65px)]"
        }`}
        ref={modalRef}
      >
        <div className="flex justify-between items-center text-xl font-semibold text-center py-3 px-8">
          <Image width={134} height={24} src="/assets/logo.svg" alt="logo" />
          <CloseIcon
            fill="#818181"
            className="cursor-pointer"
            onClick={() => {
              setIsFilterModal(false);
              document.body.style.overflowY = "scroll";
            }}
          />
        </div>
        <div
          className={`w-full px-8 h-[calc(90vh-215px)] overflow-auto position-relative 
        }`}
        >
          <p className="text-lg text-[#333] font-semibold pt-4 pb-2 text-center">
            Filters
          </p>
          <div
            className={`w-full whitespace-nowrap overflow-auto scrollbar-hide h-auto ${
              isMobileDevice() && "h-100"
            }`}
          >
            {" "}
            <PriceSection
              parentMinPrice={minPrice}
              setParentMinPrice={setMinPriceInModal}
              parentMaxPrice={maxPrice}
              setParentMaxPrice={setMaxPriceInModal}
              triggerReset={triggerReset}
            />
            <YearSection
              parentMinYear={minYear}
              setParentMinYear={setMinYearInModal}
              parentMaxYear={maxYear}
              setParentMaxYear={setMaxYearInModal}
              triggerReset={triggerReset}
            />
            <MilesSection
              parentMinMiles={minMiles}
              setParentMinMiles={setMinMilesInModal}
              parentMaxMiles={maxMiles}
              setParentMaxMiles={setMaxMilesInModal}
              triggerReset={triggerReset}
            />
            <div className="flex justify-between items-center py-4">
              <div className="flex items-center space-x-2">
                <div className="relative w-[40px] h-[40px]">
                  <div>
                    <Image
                      width={40}
                      height={40}
                      className="w-full rounded-full"
                      src="/assets/defaultImg.png"
                      alt="profile"
                    />
                    <Image
                      width={16}
                      height={16}
                      className="absolute top-[25px] left-[25px]"
                      src="/assets/verified.svg"
                      alt="verified"
                    />
                  </div>
                </div>
                <p className="text-lg font-semibold">Seller fully verified</p>
              </div>
              <div
                className={`w-[50px] flex justify-center ${
                  isVerifiedInModal
                    ? "bg-[#25a5cb] border-[#25a5cb]"
                    : "bg-[#b3b3b3] border-[#b3b3b3]"
                } text-sm font-bold py-0.5 px-0.5 border rounded-full`}
                onClick={() => {
                  changeVerified();
                }}
              >
                <button
                  className={`w-full ${
                    isVerifiedInModal
                      ? "bg-[#25a5cb] border-[#25a5cb] w-[22px] h-[25px]"
                      : "bg-white w-[25px] h-[25px]"
                  } border  rounded-full`}
                ></button>
                <button
                  className={`w-full ${
                    isVerifiedInModal
                      ? "bg-white w-[22px] h-[25px]"
                      : "bg-[#b3b3b3] border-[#b3b3b3] w-[25px] h-[25px]"
                  } border  rounded-full`}
                ></button>
              </div>
            </div>
            <hr className="w-full mt-3" />
            <CarTypeSection
              bodyType={bodyType}
              setBodyType={setBodyTypeInModal}
              triggerReset={triggerReset}
            />
            <MoreFilterSection
              moreFilterData={moreFilterData}
              moreFiltersArr={moreFiltersArr}
              setMoreFiltersArr={setMoreFiltersArrInModal}
              triggerReset={triggerReset}
            />
          </div>
        </div>
        <hr className="w-full mt-4" />
        <div className="flex justify-between px-4 pt-4">
          <button
            className="bg-[#f7f9fc] text-[#2c564a] text-sm font-medium py-2 px-3 rounded"
            onClick={() => clear()}
          >
            Clear
          </button>
          <button
            className="bg-[#00b3de] text-white text-sm font-bold py-2 px-3 rounded cursor-pointer"
            onClick={() => save()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
